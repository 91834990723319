import Vue from "vue";

let config = {}
if(process.env.NODE_ENV !== 'production'){
	//开发环境
	config = {
		//baseUrl : "http://192.168.0.126:8880",
		// baseUrl : "http://localhost:8880",
		//baseUrl : "http://192.168.1.28:8880",
		// baseUrl:"https://p81api.web3dev.vip/",
		// baseUrl:"http://192.168.110.53/",


		baseUrl: "https://exch1api.bullbi.com/",

		// baseUrl: "http://192.168.1.5/",

		nettyWssUrl: 'wss://stream.tueex.io',
  		chatWsUrl:'wss://stream.tueex.io',
  		drawWsUrl:'wss://stream.tueex.io',
		domain:'https://wap.tueex.io'
	}
}else{
	//生产环境
	config = {
		// //baseUrl : "https://api.tueex.io",
		// baseUrl : "http://103.224.250.92:8880",
		// nettyWssUrl: 'wss://stream.tueex.io',
  		// chatWsUrl:'wss://stream.tueex.io',
  		// drawWsUrl:'wss://stream.tueex.io',
		// //domain:'https://wap.tueex.io'
		// domain:'http://www.tueex.com'


        //baseUrl : "http://192.168.0.126:8880",
        baseUrl : "http://localhost:8880",
        //baseUrl : "http://192.168.1.28:8880",
        // nettyWssUrl: 'wss://stream.tueex.io',
        // chatWsUrl:'wss://stream.tueex.io',
        // drawWsUrl:'wss://stream.tueex.io',
        domain:'https://wap.tueex.io'
	}
}
export default config;
